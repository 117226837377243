<template>
  <div class="wa--servers">
    <h1 class="wind--title">
      服务器信息
    </h1>
    <hr class="wind--divider wind--divider--invisible" />
    <div class="wa--servers-couchdb">
      <h3 class="wind--title">
        CouchDB (数据库)
        <cv-tag
          :label="couchdbUp ? '在线' : '不在线'"
          :kind="couchdbUp ? 'green' : 'red'">
        </cv-tag>
      </h3>
      <div v-if="couchdb">
        <wa-name-value
          name="版本"
          :value="couchdb.version">
        </wa-name-value>
        <wa-name-value
          name="开发商"
          :value="couchdb.vendor.name">
        </wa-name-value>
        <wa-name-value
          name="UUID"
          :value="couchdb.uuid">
        </wa-name-value>
        <wa-name-value
          name="功能"
          :value="couchdb.features.join(', ')">
        </wa-name-value>
      </div>
    </div>
    <hr class="wind--divider wind--divider--invisible" />
    <div class="wa--servers-windproc">
      <h3 class="wind--title">
        WindProc (计算服务器)
        <cv-tag
          :label="windprocUp ? '在线' : '不在线'"
          :kind="windprocUp ? 'green' : 'red'">
        </cv-tag>
      </h3>
      <div v-if="windproc">
        <wa-name-value
          name="在线worker"
          :value="windproc.status.active_workers.join(', ')">
        </wa-name-value>
        <wa-name-value
          name="闲置worker"
          :value="windproc.status.idle_workers.length == 0 ? '无' : windproc.status.idle_workers.join(', ')">
        </wa-name-value>
        <wa-name-value
          name="现有计算"
          :value="Object.keys(windproc.status.active_calculations).length == 0 ? '无' : Object.keys(windproc.status.active_calculations).join(', ')">
        </wa-name-value>
      </div>
    </div>
  </div>
</template>
<script>
import { WaNameValue } from '../components'
import {
  CvTag
} from '@carbon/vue/src/index'
export default {
  name: 'Servers',
  components: {
    WaNameValue,
    CvTag
  },
  data: () => ({
    couchdb: null,
    couchdbUp: false,
    windproc: null,
    windprocUp: false
  }),
  created (){
    this.$api.server.databaseWelcome().then( r => {
      if (r.ok) {
        this.couchdb = r.data
        return this.$api.server.getDatabaseStatus()
      } else {
        return false
      }
    }).then( r => {
      if (r) {
        if (r.ok) {
          this.couchdbUp = true
        }
      }
    })

    this.$api.server.getWindprocStatus().then( r => {
      if (r.ok) {
        this.windproc = r.data
        this.windprocUp = true
      }
    })
  }
}
</script>